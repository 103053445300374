<template>
  <div class="h-100vh pt-50 pb-50 m-left">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="internal-error text-center">
          <v-img
            src="../assets/img/error.png"
            class="d-block mb-30"
            alt="error"
          />
          <h4 class="mb-15 ms-auto me-auto">
            Al parecer ha habido un error interno en el sistema, por favor intenta volver mas tarde
          </h4>
          <router-link class="success mt-10" to="/">
            Volver al inicio
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error500Page",
};
</script>

<style lang="scss" scoped>
.internal-error {
  h4 {
    max-width: 400px;
    line-height: 1.4;
  }
  button,
  a {
    padding: 14px 35px !important;
    border-radius: 30px !important;
    display: inline-block;

    &.success {
      background-color: var(--heraBlueColor);
      color: var(--whiteColor);
    }
    font: {
      weight: 600 !important;
      size: 14px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .internal-error {
    h4 {
      max-width: 400px;
      line-height: 1.4;
    }
  }
}
</style>