<template>
  <v-card class="mb-25 pingo-card">
    <div class="v-card-header border-bottom">
      <h5 class="mb-0">Your Progress</h5>
      <v-menu>
        <template v-slot:activator="{ props }">
          <button
            type="button"
            v-bind="props"
            class="position-relative btn bg-transparent border-none"
          >
            This Week
          </button>
        </template>
        <v-list class="monthMenu">
          <button type="button" class="bg-transparent border-none">
            This Month
          </button>
          <button type="button" class="bg-transparent border-none">
            This Year
          </button>
          <button type="button" class="bg-transparent border-none">
            All Time
          </button>
        </v-list>
      </v-menu>
    </div>
    <div class="chart yourProgressChart">
      <apexchart
        type="line"
        height="320"
        :options="yourProgressChart"
        :series="progress"
      ></apexchart>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "YourProgressChart",
  data: function () {
    return {
      progress: [
        {
          name: "Theory",
          data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51],
        },
        {
          name: "Practice",
          data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56],
        },
      ],
      yourProgressChart: {
        chart: {
          height: 320,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        colors: ["#2DB6F5", "#757FEF"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        legend: {
          offsetY: 3,
          position: "top",
          fontSize: "14px",
          horizontalAlign: "center",
          labels: {
            colors: "#5B5B98",
          },
        },
        yaxis: {
          tickAmount: 4,
          labels: {
            style: {
              colors: "#a9a9c8",
              fontSize: "14px",
            },
          },
          axisBorder: {
            show: false,
          },
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          labels: {
            trim: false,
            style: {
              colors: "#a9a9c8",
              fontSize: "14px",
            },
          },
          categories: [
            "01 Jan",
            "02 Jan",
            "03 Jan",
            "04 Jan",
            "05 Jan",
            "06 Jan",
            "07 Jan",
            "08 Jan",
            "09 Jan",
            "10 Jan",
          ],
        },
        tooltip: {
          y: {
            formatter: function (val: unknown) {
              return val + " hours";
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#EDEFF5",
          strokeDashArray: 5,
        },
      },
    };
  },
});
</script>