<template>
  <MainSidebar v-if="shouldShowSidebar" />
  <div
    :class="[
      'main-content d-flex flex-column',
      { active: stateStoreInstance.open },
      { 'padding-left-0': shouldShowPaddingLeftZero },
    ]"
  >
    <MainHeader v-if="shouldShowHeader" />
    <router-view />
    <div class="flex-grow-1"></div>
    <MainFooter v-if="shouldShowFooter" />
  </div>
</template>

<script lang="ts">
import { defineComponent, watchEffect } from "vue";
import stateStore from "./utils/store";
import { useRoute } from "vue-router";

import MainHeader from "./components/Layouts/MainHeader.vue";
import MainSidebar from "./components/Layouts/MainSidebar.vue";
import MainFooter from "./components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "App",
  components: {
    MainHeader,
    MainSidebar,
    MainFooter,
  },
  mounted() {
    document.body.classList.add("bg-body-secondary");
  },
  setup() {
    const stateStoreInstance = stateStore;
    watchEffect(() => {
      if (stateStoreInstance.open) {
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");
        console.log("show");
      } else {
        document.body.classList.remove("sidebar-hide");
        document.body.classList.add("sidebar-show");
      }
    });

    return {
      stateStoreInstance,
    };
  },
  computed: {
    shouldShowSidebar() {
      const route = useRoute();
      const hiddenRoutes = [
        "/",
        "/error-500",
        "/coming-soon",
        "/authentication/login",
        "/authentication/logout",
        "/authentication/register",
        "/authentication/signin-signup",
        "/authentication/forgot-password",
        "/authentication/reset-password",
        "/authentication/confirm-mail",
        "/authentication/lock-screen",
      ];
      return !hiddenRoutes.includes(route.path);
    },
    shouldShowHeader() {
      const route = useRoute();
      const hiddenRoutes = [
        "/",
        "/error-500",
        "/coming-soon",
        "/authentication/login",
        "/authentication/logout",
        "/authentication/register",
        "/authentication/signin-signup",
        "/authentication/forgot-password",
        "/authentication/reset-password",
        "/authentication/confirm-mail",
        "/authentication/lock-screen",
      ];
      return !hiddenRoutes.includes(route.path);
    },
    shouldShowFooter() {
      const route = useRoute();
      const hiddenRoutes = [
        "/",
        "/error-500",
        "/coming-soon",
        "/authentication/login",
        "/authentication/logout",
        "/authentication/register",
        "/authentication/signin-signup",
        "/authentication/forgot-password",
        "/authentication/reset-password",
        "/authentication/confirm-mail",
        "/authentication/lock-screen",
      ];
      return !hiddenRoutes.includes(route.path);
    },
    shouldShowPaddingLeftZero() {
      const route = useRoute();
      const specialPaddingRoutes = [
        "/",
        "/error-500",
        "/coming-soon",
        "/authentication/login",
        "/authentication/logout",
        "/authentication/register",
        "/authentication/signin-signup",
        "/authentication/forgot-password",
        "/authentication/reset-password",
        "/authentication/confirm-mail",
        "/authentication/lock-screen",
      ];
      return specialPaddingRoutes.includes(route.path);
    },
  },
});
</script>

<style lang="scss" scoped>
.main-content {
  overflow: hidden;
  min-height: 100vh;
  transition: var(--transition);
  padding: {
    left: 285px;
    right: 25px;
  }
  &.active {
    padding-left: 85px;
  }
  &.right-sidebar {
    padding: {
      left: 25px;
      right: 285px;
    }
    &.active {
      padding-right: 85px;
    }
  }
  &.hide-sidebar {
    padding-left: 85px;

    &.right-sidebar {
      padding: {
        left: 25px;
        right: 85px;
      }
    }
  }
  &.padding-left-0 {
    padding-left: 25px !important;
  }
}
.blank-page {
  app-sidebar,
  app-footer,
  app-header {
    display: none !important;
  }
  .main-content {
    padding: 0 !important;
  }
}
.rtl-enabled {
  .main-content {
    padding: {
      right: 285px;
      left: 25px;
    }
    &.active {
      padding: {
        left: 25px;
        right: 85px;
      }
    }
    &.right-sidebar {
      padding: {
        right: 25px;
        left: 285px;
      }
      &.active {
        padding: {
          right: 25px;
          left: 85px;
        }
      }
    }
    &.hide-sidebar {
      padding: {
        left: 25px;
        right: 85px;
      }
      &.right-sidebar {
        padding: {
          right: 25px;
          left: 85px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .main-content {
    padding: {
      left: 15px;
      right: 15px;
    }
    &.active {
      padding-left: 15px;
    }
    &.right-sidebar {
      padding: {
        left: 15px;
        right: 15px;
      }
      &.active {
        padding-right: 15px;
      }
    }
    &.hide-sidebar {
      padding-left: 15px;

      &.right-sidebar {
        padding: {
          left: 15px;
          right: 15px;
        }
      }
    }
  }
  .rtl-enabled {
    .main-content {
      padding: {
        left: 15px;
        right: 15px;
      }
      &.active {
        padding-right: 15px;
      }
      &.right-sidebar {
        padding: {
          left: 15px;
          right: 15px;
        }
        &.active {
          padding-left: 15px;
        }
      }
      &.hide-sidebar {
        padding-right: 15px;

        &.right-sidebar {
          padding: {
            left: 15px;
            right: 15px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-content {
    padding: {
      left: 25px;
      right: 25px;
    }
    &.active {
      padding-left: 25px;
    }
    &.right-sidebar {
      padding: {
        left: 25px;
        right: 25px;
      }
      &.active {
        padding-right: 25px;
      }
    }
    &.hide-sidebar {
      padding-left: 25px;

      &.right-sidebar {
        padding: {
          left: 25px;
          right: 25px;
        }
      }
    }
  }
  .rtl-enabled {
    .main-content {
      padding: {
        left: 25px;
        right: 25px;
      }
      &.active {
        padding-right: 25px;
      }
      &.right-sidebar {
        padding: {
          left: 25px;
          right: 25px;
        }
        &.active {
          padding-left: 25px;
        }
      }
      &.hide-sidebar {
        padding-right: 25px;

        &.right-sidebar {
          padding: {
            left: 25px;
            right: 25px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-content {
    padding: {
      left: 25px;
      right: 25px;
    }
    &.active {
      padding-left: 25px;
    }
    &.right-sidebar {
      padding: {
        left: 25px;
        right: 25px;
      }
      &.active {
        padding-right: 25px;
      }
    }
    &.hide-sidebar {
      padding-left: 25px;

      &.right-sidebar {
        padding: {
          left: 25px;
          right: 25px;
        }
      }
    }
  }
  .rtl-enabled {
    .main-content {
      padding: {
        left: 25px;
        right: 25px;
      }
      &.active {
        padding-right: 25px;
      }
      &.right-sidebar {
        padding: {
          left: 25px;
          right: 25px;
        }
        &.active {
          padding-left: 25px;
        }
      }
      &.hide-sidebar {
        padding-right: 25px;

        &.right-sidebar {
          padding: {
            left: 25px;
            right: 25px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-content {
    padding-left: 265px;

    &.active {
      padding-left: 85px;
    }
    &.right-sidebar {
      padding-right: 265px;

      &.active {
        padding-right: 85px;
      }
    }
    &.hide-sidebar {
      padding-left: 85px;

      &.right-sidebar {
        padding: {
          left: 25px;
          right: 85px;
        }
      }
    }
  }
  .rtl-enabled {
    .main-content {
      padding: {
        left: 25px;
        right: 265px;
      }
      &.active {
        padding: {
          left: 25px;
          right: 85px;
        }
      }
      &.right-sidebar {
        padding: {
          left: 265px;
          right: 25px;
        }
        &.active {
          padding: {
            right: 25px;
            left: 85px;
          }
        }
      }
      &.hide-sidebar {
        padding: {
          left: 25px;
          right: 85px;
        }
        &.right-sidebar {
          padding: {
            right: 25px;
            left: 85px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .main-content {
    padding: {
      left: 325px;
      right: 25px;
    }
    &.active {
      padding-left: 85px;
    }
    &.right-sidebar {
      padding: {
        left: 25px;
        right: 325px;
      }
      &.active {
        padding-right: 85px;
      }
    }
    &.hide-sidebar {
      padding-left: 85px;

      &.right-sidebar {
        padding: {
          left: 25px;
          right: 85px;
        }
      }
    }
  }
  .rtl-enabled {
    .main-content {
      padding: {
        right: 325px;
        left: 25px;
      }
      &.active {
        padding: {
          left: 25px;
          right: 85px;
        }
      }
      &.right-sidebar {
        padding: {
          right: 25px;
          left: 325px;
        }
        &.active {
          padding: {
            left: 85px;
            right: 25px;
          }
        }
      }
      &.hide-sidebar {
        padding: {
          left: 25px;
          right: 85px;
        }
        &.right-sidebar {
          padding: {
            right: 25px;
            left: 85px;
          }
        }
      }
    }
  }
}
</style>