
import { formatDate } from 'date-fns';
<template>
    <PageTitle pageTitle="Listado de Mantenciones y revisiones técnicas" mainTitle="Mantenciones y reviciones técnicas" actualRoute="Mantención de vehículos" linkTo="/mantenciones"/>

    <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-title>
        <div class="row">
          <h5>Filtros</h5>
        </div>
        
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <form>
          <div class="row mt-4">
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-text-field label="Id"></v-text-field>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-select
                  v-model="defaultTipos"
                  label="Tipo"
                  :items="tipos"
                  return-object
                ></v-select>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-text-field label="Patente"></v-text-field>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-select
                  ref="selectMarca"
                  v-model="defaultMarca"
                  label="Marca"
                  :items="marcas"
                  return-object
                ></v-select>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-select
                  v-model="defaultModelo"
                  label="Modelo"
                  :items="modelos"
                  return-object
                ></v-select>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-text-field label="Kilometraje"></v-text-field>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-text-field label="KM programado"></v-text-field>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <v-text-field type="date" class="input-control" label="Fecha revisión técnica"></v-text-field>
                </div>
              </div>
          </div>
        </form>
        <v-btn color="grey" elevation="4" prepend-icon="flaticon-refresh" size="large">
            <template v-slot:prepend>
              <v-icon color="white"></v-icon>
            </template>
            Limpiar
        </v-btn>
        <v-btn color="green" elevation="4" prepend-icon="flaticon-search" size="large" class="ml-5">
            <template v-slot:prepend>
              <v-icon color="white"></v-icon>
            </template>
            Buscar
        </v-btn>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

    <v-card class="mb-25 pingo-card mt-20">
    <div class="v-card-header d-flex">
        <h5 class="mb-0">Mantenciones y revisiones técnicas</h5>
    </div>
    <div class="pingo-table-content">
      <div class="v-elevation-z8 recentOrdersTable pingo-table">
        <v-table>
          <thead>
            <tr>
              <th class="text-start">Id</th>
              <th class="text-start">Tipo</th>
              <th class="text-center">Patente</th>
              <th class="text-center">Marca</th>
              <th class="text-center">Modelo</th>
              <th class="text-center">Kilometraje</th>
              <th class="text-start">Descripción</th>
              <th class="text-center">KM programado</th>
              <th class="text-center">Fecha revisión técnica</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="manRev in getMR" :key="manRev.id">
              <td class="text-start">{{ manRev.id }}</td>
              <td class="text-start">{{ manRev.tipo }}</td>
              <td>{{ manRev.patente }}</td>
              <td>{{ manRev.marca }}</td>
              <td>{{ manRev.modelo }}</td>
              <td>{{ manRev.kilometraje }}</td>
              <td class="text-start">{{ manRev.descripcion }}</td>
              <td>{{ manRev.kilometroProgramado ?? '' }}</td>
              <td>{{ manRev.proximaFechaRevision ? formatDate(manRev.proximaFechaRevision) : '' }}</td>
            </tr>
          </tbody>
        </v-table>
        <ItemsPerPage />
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
    import PageTitle from "../../../components/Common/PageTitle.vue";
    import manRev from '../../../data/mantenciones.json'
    import marcas from '../../../data/marcas.json'
    import modelos from '../../../data/modelos.json'
    import { format } from 'date-fns' 

    export default {
        components: {
          PageTitle,
        },
        data(){
          return {
            defaultMarca: 'Todas',
            defaultModelo: 'Todos',
            defaultTipos: 'Todos',
            tipos: ['Todos', 'Mantención', 'Revisión técnica']
          }
        },
        methods: {
            formatDate(date: string){
                return format(new Date(date), 'dd-MM-yyyy');
            },
        },
        computed: {
            getMR(){
                return manRev
            },
            marcas(){
              let m = ['Todas']
              for (const marca of marcas) {
                m.push(marca.nombre)
              }
              return m
            },
            modelos(){
              let m = ['Todos']
              for (const modelo of modelos) {
                m.push(modelo.nombre)
              }
              return m
            },
        }
    }
</script>

<style>
    .failed-class {
    color: #ee368c !important;
    }

    .passed-class {
    color: #00b69b !important;
    }
</style>
