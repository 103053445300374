<template>
  <div class="h-100vh pt-50 pb-50">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="pingo-form ms-auto me-auto">
          <div class="title">
            <div class="d-flex mb-10 align-items-center justify-content-center">
              <!-- <h2 class="mb-0 me-30">Rent a car</h2> -->
              <v-img src="../../assets/img/multicar2.png" alt="logo-icon" />
            </div>
          </div>
          <!-- <form> -->
            <div class="bg-white border-radius p-20">
              <div class="pingo-form-group without-icon">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Email <span>*</span>
                </label>
                <v-text-field
                  type="email"
                  label="Ingrese su email"
                ></v-text-field>
              </div>
              <div class="pingo-form-group without-icon mb-0">
                <label class="d-block mb-12 fw-semibold gray-color">
                  Contraseña
                  <span>*</span>
                </label>
                <v-text-field type="password" label="Ingrese su contraseña"></v-text-field>
              </div>
            </div>
            <router-link to="/home">
              <button class="success d-block fw-semibold">Iniciar sesión</button>
            </router-link>
            
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginPage",
};
</script>

<style lang="scss" scoped>
.v-selection-control {
  .v-label {
    white-space: nowrap !important;
  }
}
button,
a {
  border-radius: 5px !important;
  &.w-auto {
    width: auto !important;
  }

  &.success {
    background-color: var(--heraBlueColor);
    color: var(--whiteColor);
  }
  &.gray {
    background-color: #edeff5;
    color: var(--blackColor);
  }
  font: {
    weight: 600 !important;
    size: 14px !important;
  }
}
.pingo-form {
  max-width: 510px;

  .title {
    margin-bottom: 30px;

    p {
      font-size: 18px;
      a {
        font-size: 18px !important;
      }
    }
  }
  .login-with-socials {
    margin-bottom: 25px;

    button {
      margin-top: 0;
      font-size: 15px !important;
      padding: 17px 25px 17px 60px !important;
      position: relative;
      border-radius: 5px !important;
      width: 100%;

      .v-img {
        top: 52%;
        position: absolute;
        transform: translateY(-52%);
        margin: {
          left: -25px;
        }
      }
      &.gray {
        border: 1px solid #edeff5 !important;
        background-color: var(--whiteColor) !important;
      }
      &.success {
        background-color: var(--heraBlueColor);
        color: var(--whiteColor);
      }
    }
  }
  .or {
    margin-bottom: 25px;
    color: #777e90;
    line-height: 1;
    z-index: 1;

    span {
      display: inline-block;
      background: #f5f5f5;
      padding: {
        left: 15px;
        right: 15px;
      }
    }
    &::before {
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      content: "";
      z-index: -1;
      position: absolute;
      background: #e2e8f0;
      transform: translateY(-50%);
    }
  }
  button {
    margin-top: 15px;
    padding: 17px 35px !important;
    width: 100%;
    font: {
      size: 18px !important;
      family: var(--headingFontFamily) !important;
    }
  }
  .info {
    margin-top: 15px;

    a {
      &:hover {
        color: var(--blackColor) !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .pingo-form {
    max-width: 100%;
    padding: {
      left: 15px;
      right: 15px;
    }
    .title {
      margin-bottom: 20px;

      p {
        font-size: 15px;
      }
    }
    .login-with-socials {
      margin-bottom: 20px;

      button {
        margin-bottom: 15px;
      }
    }
    .or {
      margin-bottom: 20px;
    }
    button {
      margin-top: 15px;
      font-size: 15px !important;
      padding: 17px 30px !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pingo-form {
    .title {
      margin-bottom: 15px;

      p {
        font-size: 15px;
      }
    }
    .login-with-socials {
      margin-bottom: 20px;
    }
    .or {
      margin-bottom: 20px;
    }
    button {
      margin-top: 15px;
      font-size: 16px !important;
    }
  }
}
</style>