import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import ErrorPage from "../pages/ErrorPage.vue";
import Error500Page from "../pages/Error500Page.vue";
import LoginPage from "../pages/Authentication/LoginPage.vue";
import LogoutPage from "../pages/Authentication/LogoutPage.vue";
import HomeViewVue from '@/views/Home/HomeView.vue';
import ContratosIndex from '@/views/Contratos/ContratosIndex.vue';
import VehiculosIndex from '@/views/Administracion/Vehiculos/VehiculosIndex.vue';
import MantencionesIndex from '@/views/MantencionVehiculos/MRTecnicas/MantencionesIndex.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/home',
    name: 'HomeView',
    component: HomeViewVue
  },
  {
    path: "/contratos",
    name: "Contratos",
    component: ContratosIndex,
  },
  {
    path: "/vehiculos",
    name: "Vehiculos",
    component: VehiculosIndex,
  },
  {
    path: "/mantenciones",
    name: "Mantenciones",
    component: MantencionesIndex,
  },
  {
    path: "/authentication/logout",
    name: "LogoutPage",
    component: LogoutPage,
  },
  // {
  //   path: "/authentication/login",
  //   name: "LoginPage",
  //   component: LoginPage,
  // },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
  { path: "/error-500", name: "Error500Page", component: Error500Page },
]

const router = createRouter({
  history: createWebHistory(process.env.NODE_ENV === "production" ? "/pingo/" : "/",),
  routes,
  linkExactActiveClass: "active",
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
})

export default router
