<template>
    <v-dialog v-model="dialog" activator="parent" width="auto">
        <div class="create-dialog-box" :style="{ width: `${dialogWidth}px !important` }">
            <div class="title d-flex align-items-center justify-space-between">
                <h4 class="mb-0">Nuevo contrato</h4>
                <button
                class="close-btn bg-transparent p-0 border-none"
                @click="dialog = false"
                >
                <i class="flaticon-close"></i>
                </button>
            </div>
            <form>
                <v-row>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Fecha salida</label>
                            <input type="date" class="input-control" />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Rut cliente</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder="12345678-9"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Nombre cliente</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder="Nombre de cliente..."
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Compañía</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder="Nombre de compañía..."
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Patente</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder="AA-BB-11"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Modelo vehículo</label>
                            <select class="input-control">
                                <option value="" selected disabled>Seleccione un modelo</option>
                                <option v-for="modelo in modelos" :key="modelo.id" :value="modelo.id">
                                    {{ modelo.nombre }}
                                </option>
                            </select>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Dias</label>
                            <input
                                type="number"
                                class="input-control"
                                placeholder="Número de días de uso del vehículo.."
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Fecha devolución</label>
                            <input type="date" class="input-control" />
                        </div>
                    </v-col>
                </v-row>
                <div class="text-end mt-15">
                    <button type="button" class="gray" @click="dialog = false">
                        <i class="ri-close-fill"></i> Cancelar
                    </button>
                    <button type="submit">
                        <i class="ri-add-fill"></i> Crear nuevo contrato
                    </button>
                </div>
            </form>
        </div>
    </v-dialog>
</template>

<script lang="ts">
    import { defineComponent } from "vue";
    import modelos from '@/data/modelos.json';

    export default defineComponent({
        name: "DialogCreate",
        props:{
            width: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                dialog: false,
                dialogWidth: this.width,
                modelos
            };
        },
    });
</script>

<style>
    .create-dialog-box{
        v-bind:width="dialogWidth + 'px !important'"
    }
</style>