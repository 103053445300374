<template>
    <LmsStats :indicadores="getIndicadores"/>
    <v-row>
        <v-col cols="12" xl="12">
            <YourProgressChart />
        </v-col>
    </v-row>
</template>

<script lang="ts" setup>
    import { ref, computed } from 'vue'
    import LmsStats from '../../components/Dashboard/LMSCourses/LmsStats.vue';
    import YourProgressChart from "../../components/Dashboard/LMSCourses/YourProgressChart.vue";
    import indicadores from '@/data/indicadores.json'
    import { LmsStat } from '@/types/LmsStats'

    const getIndicadores = computed( () => {
        const ind: LmsStat[] = indicadores
        return ind
    })

    // export default {
    //     components: {
    //         LmsStats,
    //         YourProgressChart
    //     },
    //     computed: {
    //         getIndicadores(): LmsStat[]{
    //             const ind: LmsStat[] = indicadores
    //             return ind
    //         }
    //     }
    // }
</script>

