import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueApexCharts from "vue3-apexcharts";
import { QuillEditor } from '@vueup/vue-quill'


import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import 'vue3-carousel/dist/carousel.css'
import "swiper/css";
import "swiper/css/bundle";

import 'remixicon/fonts/remixicon.css'
import "./assets/custom.scss";
import 'vuetify/dist/vuetify.min.css'

const app = createApp(App)
  .use(router)
  .use(vuetify)
  app.use(VueApexCharts);
  app.component('QuillEditor', QuillEditor)
  app.mount('#app')
