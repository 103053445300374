<template>
    <v-dialog v-model="dialog" activator="parent" width="auto" scrollable>
        <div class="create-dialog-box" :style="{ width: `${dialogWidth}px !important` }">
            <div class="title d-flex align-items-center justify-space-between">
                <h4 class="mb-0">Editando contrato</h4>
                <button
                class="close-btn bg-transparent p-0 border-none"
                @click="dialog = false"
                >
                <i class="flaticon-close"></i>
                </button>
            </div>
            <form>
                <v-row>
                    <v-col cols="12" lg="12" md="12">
                        <div class="form-group">
                            <label class="d-block fw-semibold">ID</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder=""
                                :value="data.id"
                                readonly
                                disabled
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Fecha salida</label>
                            <input type="date" class="input-control" :value="getFechaIngreso()"/>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Rut cliente</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder="12345678-9"
                                :value="data.rut_cliente"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Nombre cliente</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder="Nombre de cliente..."
                                :value="data.nombre_cliente"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Compañía</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder="Nombre de compañía..."
                                :value="data.compania"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Patente</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder="AA-BB-11"
                                :value="data.patente"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Modelo vehículo</label>
                            <select class="input-control" v-model="modelo">
                                <option v-for="modelo in modelos" :key="modelo.id" :value="modelo.id">
                                    {{ modelo.nombre }}
                                </option>
                            </select>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Dias</label>
                            <input
                                type="number"
                                class="input-control"
                                placeholder="Número de días de uso del vehículo.."
                                :value="data.dias"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Fecha devolución</label>
                            <input type="date" class="input-control" :value="getFechaDevolucion()"/>
                        </div>
                    </v-col>
                </v-row>
                <div class="text-end mt-15">
                <button type="button" class="gray" @click="dialog = false">
                    <i class="ri-close-fill"></i> Cancelar
                </button>
                <button type="submit">
                    <i class="ri-loop-left-line"></i> Actualizar contrato
                </button>
                </div>
            </form>
        </div>
    </v-dialog>
</template>

<script lang="ts">
    import { defineComponent } from "vue";
    import { format } from 'date-fns' 
    import modelos from '@/data/modelos.json';

    export default defineComponent({
        name: "DialogEdit",
        props:{
            width: {
                type: String,
                required: true
            },
            data: {
                type: Object,
                required: true
            }
        },
        methods: {
            formatDate(date: string){
                return format(new Date(date), 'yyyy-MM-dd');
            },
            getFechaIngreso(){
                return this.formatDate(this.data.fecha_ingreso)
            },
            getFechaDevolucion(){
                return this.formatDate(this.data.fecha_devolucion)
            }
        },
        data() {
            return {
                dialog: false,
                dialogWidth: this.width,
                modelos,
                modelo: this.data.modelo,
                estado: this.data.estado
            };
        },
    });
</script>

<style>
    .create-dialog-box{
        v-bind:width="dialogWidth + 'px !important'"
    }
</style>