<template>
  <div class="not-found text-center">
    <v-img
      src="../assets/img/not-found.gif"
      class="d-block mb-25"
      alt="not-found"
    />
    <h4 class="mb-15">Oops!! La página que estas buscando no existe</h4>
    <p class="ms-auto me-auto">
      La página que estas buscando probablemente no existe o fue removida temporalmente
    </p>
    <div class="mt-10">
      <router-link class="success" to="/">Volver al inicio</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
};
</script>

<style lang="scss" scoped>
.not-found {
  padding: {
    top: 100px;
    bottom: 100px;
  }
  p {
    max-width: 540px;
  }

  button,
  a {
    padding: 14px 35px !important;
    border-radius: 30px !important;
    display: inline-block;

    &.success {
      background-color: var(--heraBlueColor);
      color: var(--whiteColor);
      display: inline-block;
    }
    font: {
      weight: 600 !important;
      size: 14px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .not-found {
    padding: {
      top: 50px;
      bottom: 50px;
    }
    p {
      max-width: 100%;
    }
  }
}
</style>