<template>
  <div
    class="breadcrumb-card mb-25 d-md-flex align-items-center justify-space-between"
  >
    <h5 class="mb-0">{{ pageTitle }}</h5>
    <ol class="breadcrumb mb-0 ps-0">
      <li class="breadcrumb-item">
        <router-link :to="linkTo">
          <i class="ri-home-8-line"></i>
          {{ actualRoute }}
        </router-link>
      </li>
      <li class="breadcrumb-item fw-semibold">{{ mainTitle }}</li>
    </ol>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageTitle",
  props: ["pageTitle", "mainTitle", "actualRoute", "linkTo"],
});
</script>