<template>
  <div class="date-btn main-color fw-semibold position-relative">
    <i class="flaticon-calendar"></i>
    {{ formattedDate }}
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "CurrentDate",
  setup() {
    const formattedDate = ref("");

    onMounted(() => {
      const months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      const currentDate = new Date();
      formattedDate.value = `${currentDate.getDate()} de ${
        months[currentDate.getMonth()]
      } del ${currentDate.getFullYear()}`;
    });

    return {
      formattedDate,
    };
  },
});
</script>