import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38509978"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "gray-color d-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, { class: "justify-content-center" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stats, (stat) => {
        return (_openBlock(), _createBlock(_component_v_col, {
          cols: "12",
          md: "6",
          lg: "4",
          class: "col-xxl-2/5 pb-0",
          key: stat.id
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, { class: "single-stats-box pingo-card mb-25" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", {
                    class: _normalizeClass(['icon', stat.class])
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(stat.icon)
                    }, null, 2)
                  ], 2),
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("h3", null, _toDisplayString(stat.number), 1),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(stat.title), 1)
                  ])
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}