<template>
    <v-dialog v-model="dialog" activator="parent" width="auto">
        <div class="create-dialog-box" :style="{ width: `${dialogWidth}px !important` }">
            <div class="title d-flex align-items-center justify-space-between">
                <h4 class="mb-0">Editar Vehículo</h4>
                <button
                class="close-btn bg-transparent p-0 border-none"
                @click="dialog = false"
                >
                <i class="flaticon-close"></i>
                </button>
            </div>
            <form>
                <v-row>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Patente</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder="AA-BB-11"
                                :value="data.patente"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">kilometraje</label>
                            <input
                                type="number"
                                class="input-control"
                                placeholder="123456"
                                :value="data.kilometraje"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Marca vehículo</label>
                            <select class="input-control" v-model="marca">
                                <option v-for="marca in marcas" :key="marca.nombre" :value="marca.nombre">
                                    {{ marca.nombre }}
                                </option>
                            </select>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Modelo vehículo</label>
                            <select class="input-control" v-model="modelo">
                                <option v-for="modelo in modelos" :key="modelo.nombre" :value="modelo.nombre">
                                    {{ modelo.nombre }}
                                </option>
                            </select>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Proveedor vehículo</label>
                            <select class="input-control" v-model="proveedor">
                                <option v-for="proveedor in proveedores" :key="proveedor.nombre" :value="proveedor.nombre">
                                    {{ proveedor.nombre }}
                                </option>
                            </select>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Estado</label>
                            <select class="input-control" v-model="estado">
                                <option value="Disponible">Disponible</option>
                                <option value="Arrendado">Arrendado</option>
                                <option value="Devolución - Cambio">Devolución - Cambio</option>
                                <option value="Siniestrado">Siniestrado</option>
                                <option value="En reparación">En reparación</option>
                                <option value="Eliminado">Eliminado</option>
                                <option value="Venta">Venta</option>                                
                            </select>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Color</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder="Gris"
                                :value="data.color"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Año</label>
                            <input
                                type="number"
                                class="input-control"
                                placeholder="2024"
                                :value="data.ano"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Revision Técnica</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder="RT2022-008"
                                :value="data.revisionTecnica"
                            />
                        </div>
                    </v-col>
                </v-row>
                <div class="text-end mt-15">
                    <button type="button" class="gray" @click="dialog = false">
                        <i class="ri-close-fill"></i> Cancelar
                    </button>
                    <button type="submit">
                        <i class="ri-loop-left-line"></i> Actualizar vehículo
                    </button>
                </div>
            </form>
        </div>
    </v-dialog>
</template>

<script lang="ts">
    import { defineComponent } from "vue";
    import marcas from '../../../data/marcas.json'
    import modelos from '../../../data/modelos.json'
    import proveedores from '../../../data/proveedores.json'

    export default defineComponent({
        name: "DialogEdit",
        props:{
            width: {
                type: String,
                required: true
            },
            data: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                dialog: false,
                dialogWidth: this.width,
                marcas,
                modelos,
                proveedores,
                modelo: this.data.modelo,
                marca: this.data.marca,
                proveedor: this.data.proveedor,
                estado: this.data.estado
            };
        },
    });
</script>

<style>
    .create-dialog-box{
        v-bind:width="dialogWidth + 'px !important'"
    }
</style>