<template>
  <PageTitle pageTitle="Listado de vehículos" mainTitle="Vehículos" actualRoute="Administración" linkTo="/vehiculos"/>

  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-title>
        <div class="row">
          <h5>Filtros</h5>
        </div>
        
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <form>
          <div class="row mt-4">
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-text-field label="Id"></v-text-field>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-text-field label="Patente"></v-text-field>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-text-field label="KM"></v-text-field>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-text-field label="Marca"></v-text-field>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-select
                  ref="selectMarca"
                  v-model="defaultMarca"
                  label="Marca"
                  :items="marcas"
                  return-object
                ></v-select>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-select
                  v-model="defaultModelo"
                  label="Modelo"
                  :items="modelos"
                  return-object
                ></v-select>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-select
                  v-model="defaultEstado"
                  label="Estado"
                  :items="estados"
                  return-object
                ></v-select>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="pingo-form-group without-icon">
                <v-select
                  v-model="defaultProveedor"
                  label="Proveedor"
                  :items="proveedores"
                  return-object
                ></v-select>
              </div>
            </div>
          </div>
        </form>
        <v-btn color="grey" elevation="4" prepend-icon="flaticon-refresh" size="large">
            <template v-slot:prepend>
              <v-icon color="white"></v-icon>
            </template>
            Limpiar
        </v-btn>
        <v-btn color="green" elevation="4" prepend-icon="flaticon-search" size="large" class="ml-5">
            <template v-slot:prepend>
              <v-icon color="white"></v-icon>
            </template>
            Buscar
        </v-btn>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
        
  <v-card class="mb-25 pingo-card mt-20">
    <div class="v-card-header d-flex">
        <h5 class="mb-0">Vehículos</h5>
        <v-btn color="blue" elevation="4" prepend-icon="flaticon-add" size="large">
            <template v-slot:prepend>
              <v-icon color="white"></v-icon>
            </template>
            Nuevo vehículo
            <DialogCreate width="1024"/>
        </v-btn>
    </div>
    <div class="pingo-table-content">
      <div class="v-elevation-z8 recentOrdersTable pingo-table">
        <v-table>
          <thead>
            <tr>
              <th class="text-start">Id</th>
              <th class="text-start">Patente</th>
              <th class="text-start">KM</th>
              <th class="text-center">Marca</th>
              <th class="text-center">Modelo</th>
              <th class="text-center">Color</th>
              <th class="text-center">Año</th>
              <th class="text-center">Estado</th>
              <th class="text-center">Proveedor</th>
              <th class="text-center">Revisión técnica</th>
              <th class="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="vehiculo in getVehiculos" :key="vehiculo.id">
              <td>{{ vehiculo.id }}</td>
              <td>{{ vehiculo.patente }}</td>
              <td>{{ vehiculo.kilometraje }}</td>
              <td>{{ vehiculo.marca }}</td>
              <td>{{ vehiculo.modelo }}</td>
              <td>{{ vehiculo.color }}</td>
              <td>{{ vehiculo.ano }}</td>
              <td>{{ vehiculo.estado }}</td>
              <td>{{ vehiculo.proveedor }}</td>
              <td>{{ vehiculo.revisionTecnica }}</td>
              <td>
                <v-btn icon="flaticon-view" title="Ver detalle"></v-btn>
                <v-btn class="ml-3" icon="flaticon-pen" title="Editar">
                  <i class="flaticon-pen mdi v-icon notranslate v-theme--light v-icon--size-default"></i>
                  <DialogEdit width="1024" :data="vehiculo"/>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
        <ItemsPerPage />
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
    import PageTitle from "../../../components/Common/PageTitle.vue";
    import DialogCreate from '../../../viewsComponents/dialogs/vehiculos/DialogCreate.vue'
    import DialogEdit from '../../../viewsComponents/dialogs/vehiculos/DialogEdit.vue'
    import vehiculos from '../../../data/vehiculos.json'
    import marcas from '../../../data/marcas.json'
    import modelos from '../../../data/modelos.json'
    import proveedores from '../../../data/proveedores.json'

    export default {
        components: {
          PageTitle,
          DialogCreate,
          DialogEdit
        },
        data(){
          return {
            defaultMarca: 'Todas',
            defaultModelo: 'Todos',
            defaultProveedor: 'Todos',
            defaultEstado: 'Todos',
            estados: ['Todos', 'Disponible', 'Arrendado', 'Devolución - Cambio', 'Siniestrado', 'En reparación', 'Eliminado','Venta']
          }
        },
        computed: {
            getVehiculos(){
                return vehiculos
            },
            marcas(){
              let m = ['Todas']
              for (const marca of marcas) {
                m.push(marca.nombre)
              }
              return m
            },
            modelos(){
              let m = ['Todos']
              for (const modelo of modelos) {
                m.push(modelo.nombre)
              }
              return m
            },
            proveedores(){
              let p = ['Todos']
              for (const proveedor of proveedores) {
                p.push(proveedor.nombre)
              }
              return p
            },
        }
    }
</script>

<style>
    .failed-class {
    color: #ee368c !important;
    }

    .passed-class {
    color: #00b69b !important;
    }
</style>
