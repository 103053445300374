<template>
    <PageTitle pageTitle="Listado de contratos" mainTitle="Listado de contratos" actualRoute="Contratos" linkTo="/contratos"/>

    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="row">
            <h5>Filtros</h5>
          </div>
          
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <form>
            <div class="row mt-4">
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <v-text-field label="Id"></v-text-field>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <v-text-field type="date" class="input-control" label="Fecha de ingreso"></v-text-field>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <v-text-field label="Rut cliente"></v-text-field>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <v-text-field label="Nombre cliente"></v-text-field>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <v-text-field label="Compañia"></v-text-field>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <v-text-field label="Patente vehículo"></v-text-field>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <v-select
                    v-model="defaultModelo"
                    label="Modelo"
                    :items="modelos"
                    return-object
                  ></v-select>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <v-text-field type="number" label="Dias"></v-text-field>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <v-text-field type="date" class="input-control" label="Fecha de devolución"></v-text-field>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3">
                <div class="pingo-form-group without-icon">
                  <v-select
                    v-model="defaultEstado"
                    label="Etado"
                    :items="estados"
                    return-object
                  ></v-select>
                </div>
              </div>
            </div>
          </form>
          <v-btn color="grey" elevation="4" prepend-icon="flaticon-refresh" size="large">
              <template v-slot:prepend>
                <v-icon color="white"></v-icon>
              </template>
              Limpiar
          </v-btn>
          <v-btn color="green" elevation="4" prepend-icon="flaticon-search" size="large" class="ml-5">
              <template v-slot:prepend>
                <v-icon color="white"></v-icon>
              </template>
              Buscar
          </v-btn>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card class="mb-25 pingo-card mt-20">
    <div class="v-card-header d-flex">
        <h5 class="mb-0">Contratos</h5>
        <v-btn color="blue" elevation="4" prepend-icon="flaticon-add" size="large">
            <template v-slot:prepend>
              <v-icon color="white"></v-icon>
            </template>
            Nuevo contrato
            <DialogCreate width="1024"/>
        </v-btn>
    </div>
    <div class="pingo-table-content">
      <div class="v-elevation-z8 recentOrdersTable pingo-table">
        <v-table>
          <thead>
            <tr>
              <th class="text-start">Id</th>
              <th class="text-start">Fecha de ingreso</th>
              <th class="text-center">Rut cliente</th>
              <th class="text-center">Nombre cliente</th>
              <th class="text-center">Compañia</th>
              <th class="text-center">Patente vehículo</th>
              <th class="text-center">Modelo vehículo</th>
              <th class="text-center">Dias</th>
              <th class="text-center">Fecha de devolución</th>
              <th class="text-center">Estado</th>
              <th class="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="contrato in getContratos" :key="contrato.id">
              <td>{{ contrato.id }}</td>
              <td>{{ formatDate(contrato.fecha_ingreso) }}</td>
              <td>{{ contrato.rut_cliente }}</td>
              <td>{{ contrato.nombre_cliente }}</td>
              <td>{{ contrato.compania }}</td>
              <td>{{ contrato.patente }}</td>
              <td>{{ getModelo(contrato.modelo) }}</td>
              <td>{{ contrato.dias }}</td>
              <td>{{ formatDate(contrato.fecha_devolucion) }}</td>
              <td>
                <span :class="computeClass(contrato.estado)" class="badge">
                    {{ getEstado(contrato.estado) }}
                </span>              
                </td>
              <td>
                <v-btn icon="flaticon-view" title="Ver detalle"></v-btn>
                <v-btn class="ml-3" icon="flaticon-pen" title="Editar">
                  <i class="flaticon-pen mdi v-icon notranslate v-theme--light v-icon--size-default"></i>
                  <DialogEdit width="1024" :data="contrato"/>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
        <ItemsPerPage />
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
    import PageTitle from "../../components/Common/PageTitle.vue";
    import DialogCreate from '../../viewsComponents/dialogs/contratos/DialogCreate.vue'
    import DialogEdit from '../../viewsComponents/dialogs/contratos/DialogEdit.vue'
    import contratos from '@/data/contratos.json'
    import modelos from '@/data/modelos.json'
    import { format } from 'date-fns' 

    export default {
        components: {
          PageTitle,
          DialogCreate,
          DialogEdit
        },
        data(){
          return {
            defaultModelo: 'Todos',
            defaultEstado: 'Todos',
            estados: ['Vigente', 'Cerrado']
          }
        },
        methods: {
            computeClass(classValue: number){
                return classValue == 1 ? 'passed-class' : 'failed-class'
            },
            getEstado(estado: number){                
                return estado == 1 ? 'Vigente' : 'Cerrado';
            },
            getModelo(modelo_id: number){
                
                let modelo = modelos.find(m => m.id == modelo_id)

                return modelo?.nombre || 'Modelo no encontrado'
            },         
            formatDate(date: string){
                return format(new Date(date), 'dd-MM-yyyy');
            },
        },
        computed: {
            getContratos(){
                return contratos
            },
            modelos(){
              let m = ['Todos']
              for (const modelo of modelos) {
                m.push(modelo.nombre)
              }
              return m
            },
        }
    }
</script>

<style>
    .failed-class {
    color: #ee368c !important;
    }

    .passed-class {
    color: #00b69b !important;
    }
</style>
