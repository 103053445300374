<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <button
        class="btn p-0 border-none rounded-circle position-relative"
        v-bind="props"
      >
        <i class="flaticon-envelope mt-2"></i>
      </button>
    </template>

    <v-list class="menu-dropdown p-0">
      <div
        class="menu-header d-flex align-content-center justify-space-between"
      >
        <h5 class="m-0">Emails</h5>
        <button type="button" class="bg-transparent p-0 border-none">
          Mark all as read
        </button>
      </div>
      <ul class="menu-body">
        <li class="position-relative">
          <div class="d-flex align-content-center">
            <div>
              <v-img
                src="../../../assets/img/user/user1.jpg"
                class="rounded-circle"
                alt="user"
              />
            </div>
            <div class="title">
              <h6>Invoices have been paid</h6>
              <span><i class="flaticon-timer"></i> 14 mins ago</span>
            </div>
          </div>
          <router-link to="/email/read" class="link-btn"></router-link>
          <button class="close-btn bg-transparent border-none p-0">
            <i class="flaticon-close"></i>
          </button>
        </li>
        <li class="position-relative">
          <div class="d-flex align-content-center">
            <div>
              <v-img
                src="../../../assets/img/user/user2.jpg"
                class="rounded-circle"
                alt="user"
              />
            </div>
            <div class="title">
              <h6>Allow users to like products</h6>
              <span><i class="flaticon-timer"></i> 30 mins ago</span>
            </div>
          </div>
          <router-link to="/email/read" class="link-btn"></router-link>
          <button class="close-btn bg-transparent border-none p-0">
            <i class="flaticon-close"></i>
          </button>
        </li>
        <li class="position-relative">
          <div class="d-flex align-content-center">
            <div>
              <v-img
                src="../../../assets/img/user/user4.jpg"
                class="rounded-circle"
                alt="user"
              />
            </div>
            <div class="title">
              <h6>Your leave is approved</h6>
              <span><i class="flaticon-timer"></i> 2 hours ago</span>
            </div>
          </div>
          <router-link to="/email/read" class="link-btn"></router-link>
          <button class="close-btn bg-transparent border-none p-0">
            <i class="flaticon-close"></i>
          </button>
        </li>
        <li class="position-relative">
          <div class="d-flex align-content-center">
            <div>
              <v-img
                src="../../../assets/img/user/user5.jpg"
                class="rounded-circle"
                alt="user"
              />
            </div>
            <div class="title">
              <h6>Live visits on our site</h6>
              <span><i class="flaticon-timer"></i> 5 hours ago</span>
            </div>
          </div>
          <router-link to="/email/read" class="link-btn"></router-link>
          <button class="close-btn bg-transparent border-none p-0">
            <i class="flaticon-close"></i>
          </button>
        </li>
      </ul>
      <div class="menu-footer">
        <router-link to="/email/inbox" class="fw-medium position-relative">
          View All
          <i class="flaticon-chevron"></i>
        </router-link>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "EmailsLists",
};
</script>