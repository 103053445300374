<template>
    <v-dialog v-model="dialog" activator="parent" width="auto">
        <div class="create-dialog-box" :style="{ width: `${dialogWidth}px !important` }">
            <div class="title d-flex align-items-center justify-space-between">
                <h4 class="mb-0">Nuevo Vehículo</h4>
                <button
                class="close-btn bg-transparent p-0 border-none"
                @click="dialog = false"
                >
                <i class="flaticon-close"></i>
                </button>
            </div>
            <form>
                <v-row>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Patente</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder="AA-BB-11"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">kilometraje</label>
                            <input
                                type="number"
                                class="input-control"
                                placeholder="123456"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Marca vehículo</label>
                            <select class="input-control">
                                <option value="">Seleccione una marca</option>
                                <option v-for="marca in marcas" :key="marca.id" :value="marca.id">
                                    {{ marca.nombre }}
                                </option>
                            </select>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Modelo vehículo</label>
                            <select class="input-control">
                                <option value="">Seleccione un modelo</option>
                                <option v-for="modelo in modelos" :key="modelo.id" :value="modelo.id">
                                    {{ modelo.nombre }}
                                </option>
                            </select>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Proveedor vehículo</label>
                            <select class="input-control">
                                <option value="">Seleccione un proveedor</option>
                                <option v-for="proveedor in proveedores" :key="proveedor.id" :value="proveedor.id">
                                    {{ proveedor.nombre }}
                                </option>
                            </select>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Estado</label>
                            <select class="input-control">
                                <option value="">Seleccione un estado</option>
                                <option value="1">Disponible</option>
                                <option value="2">Arrendado</option>
                                <option value="3">Devolución - Cambio</option>
                                <option value="4">Siniestrado</option>
                                <option value="5">En reparación</option>
                                <option value="6">Eliminado</option>
                                <option value="7">Venta</option>                                
                            </select>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Color</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder="Gris"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Año</label>
                            <input
                                type="number"
                                class="input-control"
                                placeholder="2024"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Revision Técnica</label>
                            <input
                                type="text"
                                class="input-control"
                                placeholder="RT2022-008"
                            />
                        </div>
                    </v-col>
                </v-row>
                <div class="text-end mt-15">
                    <button type="button" class="gray" @click="dialog = false">
                        <i class="ri-close-fill"></i> Cancelar
                    </button>
                    <button type="submit">
                        <i class="ri-add-fill"></i> Crear nuevo vehículo
                    </button>
                </div>
            </form>
        </div>
    </v-dialog>
</template>

<script lang="ts">
    import { defineComponent } from "vue";
    import marcas from '../../../data/marcas.json'
    import modelos from '../../../data/modelos.json'
    import proveedores from '../../../data/proveedores.json'

    export default defineComponent({
        name: "DialogCreate",
        props:{
            width: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                dialog: false,
                dialogWidth: this.width,
                marcas,
                modelos,
                proveedores
            };
        },
    });
</script>

<style>
    .create-dialog-box{
        v-bind:width="dialogWidth + 'px !important'"
    }
</style>