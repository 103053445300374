<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <button
        class="profile-btn main-color border-none bg-transparent position-relative"
        v-bind="props"
      >
        <v-img
          src="../../../assets/img/admin.jpg"
          class="rounded-circle"
          alt="admin"
        />
        Admin
        <span class="fw-semibold">Soltecno</span>
      </button>
    </template>
    <v-list class="profile-menu-dropdown">
      <ul class="m-0">
        <li>
          <router-link to="/profile">
            <i class="ri-user-3-line"></i> Profile
          </router-link>
        </li>
        <li>
          <router-link to="/account">
            <i class="ri-settings-3-line"></i> Settings
          </router-link>
        </li>
        <li>
          <router-link to="/">
            <i class="ri-shut-down-line"></i> Logout
          </router-link>
        </li>
      </ul>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "AdminProfile",
};
</script>